import {get} from "@/request";
import {
  IStatisticsSuperstoreDataParam,
  IStatisticsSuperstoreDataRes
} from "@/apis/statistics/superstoreData/types";

export const dataStatCQueryByPageApi = (data: IStatisticsSuperstoreDataParam) => get<IStatisticsSuperstoreDataRes>("/admin/coop/spm/data/stat/queryByPage", data)

export const dataStatCInvitationToEnterApi = (params: any) => get("/admin/coop/spm/data/stat/queryInviteVisitNumByPage", params)

export const dataStatCVisitsDetailApi = (params: any) => get("/admin/coop/spm/data/stat/queryInviteVisitDetailByPage", params)

export const dataStatCGenerateOrdersApi = (params: any) => get("/admin/coop/spm/data/stat/queryUserOrderNumByPage", params)

export const dataStatCGenerateOrdersDetailApi = (params: any) => get("/admin/coop/spm/data/stat/queryUserOrderDetailByPage", params)

export const dataStatCRechargeMemberNumberApi = (params: any) => get("/admin/coop/spm/data/stat/queryRechargeMemberNumByPage", params)

export const dataStatCQueryByExportApi = (params: any) => get("/admin/coop/spm/data/stat/queryByExport", params, "blob")
